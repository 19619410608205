import { IBaseAgency } from '../../models/agency.interface';

export class SetFranchiseContextId {
    public static readonly type = '[FranchiseContext] SetFranchiseContextId';

    constructor(public payload: { id: number }) {}
}
export class SetFranchiseContextSelectedAgency {
    public static readonly type = '[FranchiseContext] SetFranchiseContextSelectedAgency';

    constructor(public payload: { agency: IBaseAgency }) {}
}
export class LoadFranchiseContextData {
    public static readonly type = '[FranchiseContext] LoadFranchiseContextData';
}

export class LoadFranchiseContext {
    public static readonly type = '[FranchiseContext] LoadFranchiseContext';
}

export class LoadFranchiseContextAgencies {
    public static readonly type = '[FranchiseContext] LoadFranchiseContextAgencies';
}
