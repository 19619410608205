import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { IFranchiseData, IFranchiseYearlySettings } from '../../models/franchise-data.interface';
import { ISchoolYearSelectorStateModel, SchoolYearSelectorState } from '../school-year-selector/school-year-selector.state';
import { tap } from 'rxjs/operators';
import { DictionaryState } from '../dictionary/dictionary.state';
import { FranchiseService } from '../../services/franchise.service';
import { LoadFranchiseContext, LoadFranchiseContextAgencies, LoadFranchiseContextData, SetFranchiseContextId, SetFranchiseContextSelectedAgency } from './franchise-context.actions';
import { IFranchiseTag, ISingleFranchise } from '../../models/franchise.interface';
import { AgenciesService } from '../../services/agencies.service';
import { IAgency, IBaseAgency } from '../../models/agency.interface';
import { of } from 'rxjs';
import { ProfileState, ProfileStateModel } from '../profile/profile.state';

export interface IFranchiseContextStateModel {
    franchiseId: number;
    selectedAgency: IBaseAgency;
    franchiseData: IFranchiseData;
    franchise: ISingleFranchise;

    simplifiedAgencies: IAgency[];
}

@State<IFranchiseContextStateModel>({
    name: 'franchiseContext',
    defaults: {
        franchiseId: null,
        selectedAgency: null,
        franchiseData: null,
        franchise: null,
        simplifiedAgencies: null,
    },
})
@Injectable()
export class FranchiseContextState {
    private _franchiseService = inject(FranchiseService);
    private _store = inject(Store);
    private _agenciesService = inject(AgenciesService);

    @Selector()
    public static getState(state: IFranchiseContextStateModel) {
        return state;
    }

    @Selector()
    public static franchiseId(state: IFranchiseContextStateModel): number {
        return state.franchiseId;
    }
    @Selector()
    public static selectedAgency(state: IFranchiseContextStateModel): IBaseAgency {
        return state.selectedAgency;
    }

    @Selector()
    public static franchise(state: IFranchiseContextStateModel): ISingleFranchise {
        return state.franchise;
    }

    @Selector()
    public static agencies(state: IFranchiseContextStateModel): IAgency[] {
        return state.simplifiedAgencies;
    }

    @Selector()
    public static franchiseData(state: IFranchiseContextStateModel): IFranchiseData {
        return state.franchiseData;
    }

    @Selector([FranchiseContextState, SchoolYearSelectorState])
    public static yearlySettings(state: IFranchiseContextStateModel, year: ISchoolYearSelectorStateModel): IFranchiseYearlySettings {
        return state.franchiseData?.franchiseSettings?.find(s => s.schoolYear.id === year.selectedYear);
    }

    @Selector()
    public static tags(state: IFranchiseContextStateModel): IFranchiseTag[] {
        return state.franchise?.tags;
    }

    @Selector()
    public static isMotherCompany(state: IFranchiseContextStateModel): boolean {
        return state.franchise?.isMotherCompany;
    }

    @Selector()
    public static isTest(state: IFranchiseContextStateModel): boolean {
        return state.franchise?.isTest;
    }

    @Selector([FranchiseContextState, ProfileState])
    public static getUserAgencies(state: IFranchiseContextStateModel, profileState: ProfileStateModel) {
        const currentRole = profileState.currentRole;

        if (currentRole.role === 'ROLE_FRANCHISEE' || currentRole.role === 'ROLE_SUPER_ADMIN') {
            return state.simplifiedAgencies;
        }

        return currentRole?.roleFranchises?.find(r => r.franchise?.id === state.franchiseId)?.agencies;
    }

    @Selector([FranchiseContextState, ProfileState])
    public static allowAllAgencies(state: IFranchiseContextStateModel, profileState: ProfileStateModel) {
        const currentRole = profileState.currentRole;

        if (currentRole.role === 'ROLE_FRANCHISEE' || currentRole.role === 'ROLE_SUPER_ADMIN') {
            return true;
        }

        return currentRole?.roleFranchises?.find(r => r.franchise?.id === state.franchiseId)?.accessToAllAgencies;
    }

    @Action(SetFranchiseContextId)
    public setFranchiseId({ patchState }: StateContext<IFranchiseContextStateModel>, { payload }: SetFranchiseContextId) {
        patchState({
            franchiseId: payload.id,
        });
    }

    @Action(SetFranchiseContextSelectedAgency)
    public setSelectedAgency({ patchState }: StateContext<IFranchiseContextStateModel>, { payload }: SetFranchiseContextSelectedAgency) {
        patchState({
            selectedAgency: payload.agency,
        });
    }

    @Action(LoadFranchiseContextData)
    public loadFranchiseData({ patchState, getState }: StateContext<IFranchiseContextStateModel>) {
        const franchiseId = getState().franchiseId;

        return this._franchiseService.getData(franchiseId).pipe(
            tap(data => {
                const years = this._store.selectSnapshot(DictionaryState.schoolYears);

                data.franchiseSettings = years.map(y => {
                    const setting = data.franchiseSettings.find(s => s.schoolYear.id === y.id);
                    return (
                        setting || {
                            schoolYear: {
                                id: y.id,
                            },
                            productFranchiseCreated: false,
                        }
                    );
                });

                patchState({
                    franchiseData: data,
                });
            })
        );
    }

    @Action(LoadFranchiseContext)
    public loadFranchise({ patchState, getState }: StateContext<IFranchiseContextStateModel>) {
        const franchiseId = getState().franchiseId;

        return this._franchiseService.getOne(franchiseId).pipe(
            tap(data => {
                patchState({
                    franchise: data,
                });
            })
        );
    }

    @Action(LoadFranchiseContextAgencies)
    public loadAgencies({ patchState, getState }: StateContext<IFranchiseContextStateModel>) {
        const franchiseId = getState().franchiseId;
        if (!franchiseId) {
            return of(false);
        }

        return this._agenciesService.getSimplified(franchiseId).pipe(tap(fs => patchState({ simplifiedAgencies: fs })));
    }
}
